<template>
  <div class="pa-2">
    <v-card flat class="pa-1">
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        :loading="loadingstatus"
        loading-text="Loading... Please wait"
      >
        <template v-slot:top>
          <v-card flat>
            <v-card-actions class="pa-0">
              <v-spacer></v-spacer>
              <div style="width: 400px" class="pt-1 pb-2">
                <v-text-field
                  v-model="search"
                  dense
                  solo-inverted
                  flat
                  label="Search"
                  hide-details
                ></v-text-field>
              </div>
              <export-excel
                v-if="desserts.length > 0"
                :data="desserts"
                type="button"
                class="pt-1 pb-2 pl-2"
              >
                <v-btn color="success"
                  ><v-icon left>mdi-microsoft-excel</v-icon>DOWNLOAD</v-btn
                >
              </export-excel>
            </v-card-actions>
          </v-card>
        </template>

        <template v-slot:item="{ item }">
          <tr>
            <td class="tdalign">{{ item.s_no }}</td>
            <td>{{ item.company_name }}</td>
            <td class="tdalign">{{ item.Limit }}</td>
            <td
              class="tdalign u"
              @click="
                editItem(item.Total, '0', item.vendor_id, item.company_name)
              "
            >
              {{ item.Total }}
            </td>

            <td
              class="tdalign u"
              @click="
                editItem(item.Pending, '10', item.vendor_id, item.company_name)
              "
              v-if="$store.state.eventInfo.approvallevel == 2"
            >
              {{ item.Pending }}
            </td>
            <td
              class="tdalign u"
              @click="
                editItem(item.Pending, '8', item.vendor_id, item.company_name)
              "
              v-else
            >
              {{ item.Pending }}
            </td>
            <td
              class="tdalign u"
              @click="
                editItem(item.Checked, '8', item.vendor_id, item.company_name)
              "
              v-if="$store.state.eventInfo.approvallevel == 2"
            >
              {{ item.Checked }}
            </td>

            <td
              class="tdalign u"
              @click="
                editItem(item.Amended, '4', item.vendor_id, item.company_name)
              "
            >
              {{ item.Amended }}
            </td>
            <td
              class="tdalign u"
              @click="
                editItem(item.Rejected, '6', item.vendor_id, item.company_name)
              "
            >
              {{ item.Rejected }}
            </td>
            <td
              class="tdalign u"
              @click="
                editItem(item.Approved, '3', item.vendor_id, item.company_name)
              "
            >
              {{ item.Approved }}
            </td>
            <td
              class="tdalign u"
              @click="
                editItem(item.Printed, '7', item.vendor_id, item.company_name)
              "
            >
              {{ item.Printed }}
            </td>
            <td
              class="tdalign u"
              @click="
                editItem(item.Cancelled, '5', item.vendor_id, item.company_name)
              "
            >
              {{ item.Cancelled }}
            </td>
            <!-- <td class="tdalign">{{ item.Total }}</td> -->
            <td class="tdalign">{{ item.Reprinted }}</td>
          </tr>
        </template>

        <template slot="body.append">
          <tr class="pink--text">
            <td colspan="2">Total</td>
            <td class="tdalign">{{ sumField("Limit") }}</td>
            <td class="tdalign">{{ sumField("Total") }}</td>
            <td class="tdalign">{{ sumField("Pending") }}</td>
            <td
              class="tdalign"
              v-if="$store.state.eventInfo.approvallevel == 2"
            >
              {{ sumField("Checked") }}
            </td>
            <td class="tdalign">{{ sumField("Amended") }}</td>
            <td class="tdalign">{{ sumField("Rejected") }}</td>
            <td class="tdalign">{{ sumField("Approved") }}</td>
            <td class="tdalign">{{ sumField("Printed") }}</td>
            <td class="tdalign">{{ sumField("Cancelled") }}</td>

            <td class="tdalign">{{ sumField("Reprinted") }}</td>
          </tr>
        </template>

        <template v-slot:no-data>
          <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
          No Record(s)
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar v-model="snackbar" shaped top :color="snackbarcolor">
      {{ snackbartext }}
      <v-btn color="indigo" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
//import { mapMutations } from 'vuex'
//import axios from "axios";
export default {
  components: {},
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      time: "",
      datebulk: new Date().toISOString().substr(0, 10),
      timebulk: "",
      modal2: false,
      modal: false,

      modaldatebulk: false,
      modaltimebulk: false,
      menu: false,
      menu2: false,
      snackbar: false,
      snackbartext: "",
      snackbarcolor: "primary",
      EntryMode: "",
      valid: false,
      validbulk: false,
      mode: "NEW",
      newmode: true,
      sheet: false,
      sheetbulk: false,
      search: "",
      loadingstatus: false,
      cardID: "",
      Selcompanytype: "",
      event_id: "",
      //companytype: [],
      companytype_id: [],
      postBody: {
        company_name: "",
        event_id: "",
        venue_id: "",
        vendor_name: "",
        vendor_email: "",
        no_of_application: "",
        deadline: "",
        companytype_id: [],
        Vendor_ID_Update: "",
      },

      headers: [],
      desserts: [],
    };
  },
  mounted() {
    sessionStorage.COMPANYID = "";
    sessionStorage.STATUSID = "";
    // sessionStorage.display_event_name = "IPL T20 2021";
    // sessionStorage.display_venue_name = "Chinaswamy Stadium";

    sessionStorage.EVENTID = "1";
    sessionStorage.VENUEID = "1";

    this.$store.commit("setPageTitle", "Application Summary");
    this.$store.commit("setPageTitleIcon", "mdi-chart-box-outline");
    //this.$store.commit("setEventName", sessionStorage.display_event_name);
    //this.$store.commit("setVenueName", sessionStorage.display_venue_name);
    this.setHeaders();
    this.Reload(
      this.$store.state.eventInfo.EventId,
      this.$store.state.eventInfo.VenueId
    );
  },
  methods: {
    showSnackbar: function (scolor, stext) {
      this.snackbar = true;
      this.snackbartext = stext;
      this.snackbarcolor = scolor;
    },

    editItem(item, pending, company_id, company_name) {
      sessionStorage.COMPANYID = company_id;
      sessionStorage.COMPANYNAME = company_name;
      sessionStorage.STATUSID = pending;
      // this.$router.push("appsummarydetail");
      this.$router.push("appreport");
    },

    sumField(key) {
      // sum data in give key (property)
      return this.desserts.reduce((a, b) => a + (b[key] || 0), 0);
    },

    setHeaders() {
      if (this.$store.state.eventInfo.approvallevel == 2) {
        this.headers = [
          {
            text: "ID",
            align: "center",
            value: "vendor_id",
            class: "blue lighten-5",
          },
          {
            text: "Company",
            value: "company_name",
            align: "left",
            class: "blue lighten-5",
          },
          {
            text: "Limit",
            value: "Limit",
            align: "right",
            class: "blue lighten-5",
            sortable: true,
          },
          {
            text: "Received",
            value: "Total",
            align: "right",
            class: "blue lighten-5",
          },

          {
            text: "Pending",
            value: "Pending",
            class: "blue lighten-5",
            align: "right",
            sortable: true,
          },
          {
            text: "Checked",
            value: "Checked",
            class: "blue lighten-5",
            align: "right",
            sortable: true,
          },
          {
            text: "Amend",
            value: "Amended",
            align: "right",
            class: "blue lighten-5",
          },
          {
            text: "Rejected",
            value: "Rejected",
            align: "right",
            class: "blue lighten-5",
          },
          {
            text: "Approved",
            value: "Approved",
            align: "right",
            class: "blue lighten-5",
          },
          {
            text: "Printed",
            value: "Printed",
            align: "right",
            class: "blue lighten-5",
          },

          {
            text: "Cancelled",
            value: "Cancelled",
            align: "right",
            class: "blue lighten-5",
          },

          {
            text: "Reprinted",
            value: "Reprinted",
            align: "right",
            class: "blue lighten-5",
          },
        ];
      } else {
        this.headers = [
          {
            text: "ID",
            align: "center",
            value: "vendor_id",
            class: "blue lighten-5",
          },
          {
            text: "Company",
            value: "company_name",
            align: "left",
            class: "blue lighten-5",
          },
          {
            text: "Limit",
            value: "Limit",
            align: "right",
            class: "blue lighten-5",
            sortable: true,
          },
          {
            text: "Received",
            value: "Total",
            align: "right",
            class: "blue lighten-5",
          },

          {
            text: "Pending",
            value: "Pending",
            class: "blue lighten-5",
            align: "right",
            sortable: true,
          },
          {
            text: "Amend",
            value: "Amended",
            align: "right",
            class: "blue lighten-5",
          },
          {
            text: "Rejected",
            value: "Rejected",
            align: "right",
            class: "blue lighten-5",
          },
          {
            text: "Approved",
            value: "Approved",
            align: "right",
            class: "blue lighten-5",
          },
          {
            text: "Printed",
            value: "Printed",
            align: "right",
            class: "blue lighten-5",
          },

          {
            text: "Cancelled",
            value: "Cancelled",
            align: "right",
            class: "blue lighten-5",
          },

          {
            text: "Reprinted",
            value: "Reprinted",
            align: "right",
            class: "blue lighten-5",
          },
        ];
      }
    },

    Reload: async function (event_id, venue_id) {
      // alert(this.$store.state.eventInfo.EventAdminID);
      this.loadingstatus = true;
      await this.$axios
        .get(
          "EventDashboard/applicationSummary/" +
            event_id +
            "/" +
            venue_id +
            "/" +
            this.$store.state.eventInfo.EventAdminID
        )
        .then((res) => {
          console.log(res.data.result);
          this.desserts = res.data.result;
          this.loadingstatus = false;
        })
        .catch()
        .finally();
    },

    close() {
      this.sheet = false;
      //  this.Reload();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>

<style scoped>
.tdalign {
  text-align: center;
  font-size: 1.1rem;
  font-weight: 600;
}
.u {
  text-decoration: underline;
  cursor: pointer;
}
</style>

